import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const PortfolioImage = ({name}) => {
  
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        nodes {
          fluid {
            originalName
          }
          gatsbyImageData(width: 280, placeholder: DOMINANT_COLOR) 
        }
      }
    }
  `)
  
  const filteredData = data.allImageSharp.nodes.filter( (element, index, array) => element.fluid.originalName === name )
  const { gatsbyImageData } = filteredData.pop()
  const image = getImage(gatsbyImageData)

  return (
    <GatsbyImage image={image} sizes="(max-width: 280px) 280px" alt="logo"/>
  )

}

// Image.propTypes = {
//   filename: PropTypes.string.isRequired
// }

export default PortfolioImage