import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"

import PortfolioImage from "../components/portfolio-image"

import {IoEnterOutline} from "react-icons/io5"

import { TechArray } from "../components/tech"
import PortfolioType  from "../components/portfolio-type"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"


const PortfolioItem = ({type, imageName, slug, langs}) => {
  return(
    <div className="mx-auto mt-11 py-5 px-3 w-72 h-72 rounded sm:border-double border-4 border-icon_color-active bg-home-main hover:bg-home-main/80 hover:border-icon_color-active/80">

            <div className="h-fit mb-5 pt-6">
              <PortfolioImage name={imageName} />
            </div>
            <div className="flex flex-initial">
                <div className="flex flex-initial w-5/6 gap-2">
                  <TechArray langList={langs} size={24} />
                </div>
                <div className="w-1/6"> 
                  <Link to={slug}>
                    <IoEnterOutline size={38} />
                  </Link>
                </div>
            </div>


            <PortfolioType type={type} className="relative -top-72 mx-auto p-4" />
            
    </div>
  )
}

const PortfolioPage = ({ pageContext }) => {

  const {  portfolioItems } = pageContext
  const { t } = useTranslation()

  return (
    <Layout lightBackground>
     <SEO title={t('navigation.portfolio')} />
      <div className="container w-5/6 mx-auto lg:pl-10 pb-10"> 

        <PageHeader>
          {t('navigation.portfolio')}
        </PageHeader>

        <div className="grid md:grid-cols-2 2xl:grid-cols-4 gap-5 text-center">

          {portfolioItems.map(item => (
            <PortfolioItem  type={item.type} slug={item.slug} imageName={item.featured_image} langs={item.technology} />
          ))}
          
        </div>
      </div>
    </Layout>
  )
}

export default PortfolioPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logo: file(base: {eq: "rocket.png"}) {
      childrenImageSharp{
              gatsbyImageData(width: 540, quality: 100, placeholder: DOMINANT_COLOR) 
      }
    }
  }
`;
